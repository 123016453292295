<template>
  <div>
    <button-top v-if="permissions.c" tooltip="Crear producto" name="Producto"
      @action="$router.push('/gestion-productos/create')"></button-top>
    <div class="row justify-content-start mt-1">
      <div class="col-4">
        <b-form-group>
          <label for="search" class="text-success">Buscar (Producto, Id. producto)</label>
          <b-input-group class="input-group-merge">
            <b-form-input id="search" v-model="search" class="form-control-merge" name="search" placeholder=""  @input="filter" />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </div>
    <b-table :items="items" :fields="fields" :busy="load">
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Cargando...</strong>
        </div>
      </template>
      <template #cell(actions)="row">
        <span v-if="permissions.u" class="ml-1 cursor-pointer" v-b-tooltip.hover.top="'Ver producto'"
          @click="$router.push(`/gestion-productos/show/${row.item.id}`)">
          <feather-icon icon="MoreVerticalIcon" size="18" />
        </span>
      </template>
    </b-table>
    <b-row class="mt-1">
      <b-col>
        <b-pagination v-model="current_page" :total-rows="total" :per-page="per_page"
          aria-controls="my-table"></b-pagination>
      </b-col>
      <b-col>
        <b-form-select class="fl" v-model="per_page" :options="options_per_page"
          title="Registros por página"></b-form-select>
      </b-col>
      <b-col cols="6"></b-col>
    </b-row>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      load: true,
      search: '',
      fields: [
        {
          key: 'id',
          label: 'Id. producto',
          thStyle: { width: "15,83%" },
        },
        {
          key: 'specie_name',
          label: 'Especie',
          thStyle: { width: "15,83%" },
        },
        {
          key: 'packaging_name',
          label: 'Empaque',
          thStyle: { width: "15,83%" },
        },
        {
          key: 'height',
          label: 'Altura',
          thStyle: { width: "15,83%" },
        },
        {
          key: 'quantity',
          label: 'Cantidad',
          thStyle: { width: "15,83%" },
          formatter: (value) => this.formatNumber(parseInt(value)),
        },
        {
          key: 'price',
          label: 'Precio',
          thStyle: { width: "15,83%" },
          formatter: (value) => this.formatCurrency(parseInt(value)),
        },
        {
          key: 'actions',
          label: '',
          thStyle: { width: "5%" },
        },
      ],
      items: [],
      current_page: 1,
      per_page: 5,
      total: 0,
      typing_timer: null,
      options_per_page: [5, 10, 15, 25, 50, 100]
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    async current_page(new_value, old_value) {
      await this.get();
    },
    async per_page(new_value, old_value) {
      await this.get();
    },
  },

  methods: {
    async init() {
      await this.getPermission();
      await this.get();
    },

    async filter() {
      clearTimeout(this.typing_timer);
      this.typing_timer = setTimeout(async () => {
        this.current_page = 1;
        await this.get();
      }, 1000);
    },

    async get() {
      this.load = true;
      this.items = [];
      this.$http
        .get('production/product/index',
          {
            params: {
              current_page: this.current_page,
              per_page: this.per_page,
              filter: this.search,
            }
          })
        .then(res => {
          this.items = res.data.data.map((value) => {
            value.created_at = value.created_at ? this.$moment(value.created_at).format('DD/MM/YYYY') : 'n/a';
            return value;
          });

          this.total = res.data.total;
        })
        .catch(error => {
          this.notify("Error", error.response.data.message, 'danger')
        }).finally(
          () => {
            this.load = false;
          }
        )
    }
  }
}
</script>
 
  